import { computed } from 'vue';

export const useBlockEcommerceProduct = (props) => {
	const productId = computed(() => props.data.product.id);
	const productSectionStyle = computed(() => props.data.settings?.styles);
	const productSectionTextColorVars = computed(() => props.data.textColorVars);
	const productSectionButtonText = computed(() => props.data.buttonText);
	const productSectionButtonStyle = computed(() => props.data.buttonStyle);
	const productSectionButtonType = computed(() => props.data.buttonType);
	const navigationArrowsColor = computed(() => props.data.navigationArrowsColor);
	const imageRatio = computed(() => props.data.imageRatio);
	const imageBorderRadius = computed(() => props.data.imageBorderRadius);

	return {
		productId,
		productSectionStyle,
		productSectionButtonText,
		productSectionButtonStyle,
		productSectionButtonType,
		productSectionTextColorVars,
		imageBorderRadius,
		navigationArrowsColor,
		imageRatio,
	};
};

<template>
	<EcommerceShoppingCart
		v-qa="'user-section-zyroecommerceshoppingcart'"
		:is-shopping-cart-open="isShoppingCartOpen"
		:is-loading="isCheckoutLoading"
		:header-height="headerHeight"
		:translations="ecommerceTranslations"
		:language="language"
		@checkout-button-click="initCheckout"
	/>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';

import EcommerceShoppingCart from '@zyro-inc/site-modules/components/ecommerce/EcommerceShoppingCart.vue';
import { isInPreviewIframe } from '@zyro-inc/site-modules/utils/isInPreviewIframe';

import { useEcommerceModal } from '@/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@/components/ecommerce/useEcommerce';

import { defineComponent } from 'vue';

export default defineComponent({
	components: {
		EcommerceShoppingCart,
	},

	props: {
		headerHeight: {
			type: Number,
			default: 0,
		},
		ecommerceTranslations: {
			type: Object,
			default: () => ({}),
		},
		language: {
			type: String,
			default: 'en',
		},
		currentPageEcommerceBlocks: {
			type: Array,
			default: () => ([]),
		},
		currentPageEcommerceComponents: {
			type: Array,
			default: () => ([]),
		},
	},

	setup(props) {
		const { openEcommerceModal } = useEcommerceModal(props);
		const { initiateCheckout } = useEcommerce(props);

		return {
			openEcommerceModal,
			initiateCheckout,
		};
	},

	computed: {
		...mapState('ecommerce', [
			'products',
			'isShoppingCartOpen',
			'isCheckoutLoading',
			'shoppingCartItems',
			'variantsQuantity',
		]),
		currentPageEcommerceElements() {
			return [
				...this.currentPageEcommerceBlocks,
				...this.currentPageEcommerceComponents,
			];
		},
		pageVariantIds() {
			let productIds = [];

			if (this.currentPageEcommerceElements.some((block) => block.type === 'BlockEcommerceProductList')) {
				productIds = this.products.map((product) => product.id);
			} else {
				productIds = this.currentPageEcommerceElements.map((item) => item.product?.id || item.settings?.productId);
			}

			// gets variants of products and filters out variant id values that are already fetched
			return this.products.filter((product) => productIds.includes(product.id))
				?.flatMap((product) => product.variants.map((variant) => variant.id))
				?.filter((variantId) => !this.variantsQuantity.some((variant) => variant.id === variantId));
		},
	},

	watch: {
		async currentPageEcommerceElements(newValue, oldValue) {
			if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
				await this.updateVariantsQuantity({
					variantIds: this.pageVariantIds,
				});
			}
		},
	},

	async created() {
		await this.getProducts();
		await this.updateStoreData({
			variantIds: this.pageVariantIds,
		});
	},

	methods: {
		...mapActions('ecommerce', [
			'getProducts',
			'setShoppingCartOpen',
			'setShoppingCartItems',
			'setIsCheckoutLoading',
			'updateStoreData',
			'updateVariantsQuantity',
		]),
		async initCheckout() {
			if (isInPreviewIframe) {
				this.openEcommerceModal('EcommerceMessageButtonDisabled');

				return;
			}

			if (this.isCheckoutLoading) {
				return;
			}

			this.setIsCheckoutLoading(true);

			await this.initiateCheckout(this.shoppingCartItems).then(() => {
				this.setShoppingCartOpen(false);
				this.setIsCheckoutLoading(false);
			});
		},
	},
});
</script>
